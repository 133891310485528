<template>
  <div>
    <!-- 搜索栏 -->
    <search-bar class="search-bar" v-model="keyword" @search="handleSearch"></search-bar>

    <!-- 列表 -->
    <div class="list">
      <!-- 列表项目 -->
      <div class="list-item" v-for="(item, index) in list" :key="index" @click="handleSwitchPage(item.activityID)">
        <!-- 图片 -->
        <img v-if="item.mainPicture !== ''" :src="item.mainPicture" :onerror="require('@/assets/images/img_fail.png')" />
        <!-- 内容块 -->
        <div class="body">
          <!-- 标题 -->
          <div class="title one-line">{{item.name}}</div>
          <!-- 内容 -->
          <div class="content flex-column-default-start">
            <span>活动对象：{{item.targetGroupLabel}}</span>
            <span>活动时间：{{item.activityTime}}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 底标 -->
    <div class="no-more" v-if="isEnd">没有更多内容了</div>
  </div>
</template>

<script>
import page from '@/mixins/page'
import SearchBar from '@/components/SearchBar'

export default {
  name: 'ActivityList',

  data () {
    return {
      list: [],
      keyword: '',
      currentPage: 1,
      pageSize: 8,
      isEnd: false
    }
  },

  components: { SearchBar },

  mixins: [page],

  created () {
    this.pageSize = Math.ceil((this.getWindowHeight() - 30 - 32) / 103)
    this.getData()
  },

  beforeRouteLeave (to, from, next) {
    from.meta.keepAlive = false
    next()
  },

  methods: {
    getData () {
      this.api.getAllActivity({ currentPage: this.currentPage, pageSize: this.pageSize, keyword: this.keyword }).then(res => {
        if (res.data.length === 0 || res.data.length < this.pageSize) this.isEnd = true
        for (const item of res.data) {
          switch (item.targetGroup) {
            case 'Public':
              item.targetGroupLabel = '所有人'
              break
            case 'Member':
              item.targetGroupLabel = '所有会员'
              break
            case 'MemberGrade':
              item.targetGroupLabel = item.memberGrade.name
          }
        }
        this.list = [...this.list, ...res.data]
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getData() })
      })
    },
    // 切换页面
    handleSwitchPage (id) {
      this.$router.push({ path: '/activity-detail', query: { id } })
    },
    // 触底函数
    hitBottom () {
      if (this.isEnd) return
      this.currentPage++
      this.getData()
    },
    // 搜索
    handleSearch () {
      this.initPage()
      this.getData()
    }
  }
}
</script>

<style scoped>
.search-bar {
  margin: 15px;
}
.list {
  margin: 15px;
}
.list-item {
  border: 1px solid #dddddd;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #eeeeee;
}
.list-item + .list-item {
  margin-top: 10px;
}

.list-item > img {
  object-fit: cover;
  width: 100%;
  max-height: 300px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.body {
  padding: 5px 15px;
  font-size: 15px;
  color: var(--normal-font-color);
}

.title {
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.content {
  padding: 10px;
  font-size: 13px;
  color: #999;
}
</style>
